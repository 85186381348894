import debounce from 'lodash/debounce';
import { useState, useRef, useEffect, useCallback } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import UserRepository from '@/users/infrastructure/repository/UserRepository';
import { emailRegexp } from '@/ui/shared/validators/user/validateEmail';

export const useMxValidation = ({ value, enable } : { value: string, enable: boolean }) => {
  const userRepository = useDIContext().container.get<UserRepository>(UserRepository);

  const [isValid, setIsValid] = useState(true);
  const checkedEmails = useRef(new Map<string, boolean>()); // a cache to optimize requests

  const checkMxValidation = async (email: string): Promise<boolean> => {
    const savedCheck = checkedEmails.current.get(email);
    if (typeof savedCheck !== 'undefined') {
      return savedCheck;
    }

    try {
      const isValid = await userRepository.checkEmailDomainValidation(email);
      checkedEmails.current.set(email, isValid);
      return isValid;
    } catch (e) {
      // Don't do anything if something happens
      return true;
    }
  };

  const debouncedRunValidation = useCallback(
    debounce(async (value: string) => {
      if (emailRegexp.test(value)) {
        const isValid = await checkMxValidation(value);
        setIsValid(isValid);
      }
    }, 300),
    [],
  );

  useEffect(() => {
    if (!enable) {
      return;
    }

    setIsValid(true);
    debouncedRunValidation(value);
  }, [enable, value]);

  return isValid;
};
